<template>
  <div>
    <a-modal
      title="School Year Confirmation"
      :visible="visibleModal"
      :confirm-loading="confirmLoadingModal"
      @ok="handleOkStatusTahunAjaran"
      @cancel="handleCancelStatusTahunAjaran"
    >
      <p>Are you sure want to change the school year ? your change will be affected to all users</p>
    </a-modal>
    <a-col :xs="24" :lg="8">
      <Card>
        <div class="cui__utils__heading mb-0">
          <strong>SCHOOL YEAR</strong>
        </div>
        <div class="text-muted mb-3">You can change school year here</div>
        <hr />
        <ListTahunAjar
          :listData="dataTahunAjaran"
          @change="changeTahunAjaran"
        />
      </Card>
    </a-col>
  </div>
  <!-- <div style="display: flex; align-items: center; justify-content: center; min-height: 60vh">
    <a-empty :image-style="{height: '30vh'}">
      <div slot="description">
        <span class="font-size-24" style="color: #0000009C">No Data</span>
        <br />
        <span style="color: #7474747a">This page still has no data yet.</span>
      </div>
    </a-empty>
    {{token}}
    <div style="height: 800px; width: 100%">
      <JitsiMeet
        ref="jitsiRef"
        domain="aws.hasanteknologi.id"
        :options="jitsiOptions"
      />
    </div>
  </div> -->
</template>

<script>
// import JitsiMeet from '@/components/app/JitsiMeet'
// import generateToken from '@/helpers/token.js'
import Card from '@/components/app/Card'
import ListTahunAjar from '@/components/app/ListTahunAjar'
export default {
  components: {
    Card,
    ListTahunAjar,
  },
  data() {
    return {
      visibleModal: false,
      confirmLoadingModal: false,
      dataTahunAjaran: [],
      selectedTahunAjaran: '',
    }
  },
  mounted() {
    this.fetchTahunAjar()
  },
  computed: {
    // jitsiOptions () {
    //   return {
    //     roomName: 'kelas2121',
    //     noSSL: false,
    //     userInfo: {
    //       email: 'user@email.com',
    //       displayName: 'ibu Sulis',
    //     },
    //     configOverwrite: {
    //       enableNoisyMicDetection: false,
    //     },
    //     interfaceConfigOverwrite: {
    //       SHOW_JITSI_WATERMARK: false,
    //       SHOW_WATERMARK_FOR_GUESTS: false,
    //       SHOW_CHROME_EXTENSION_BANNER: false,
    //       SHOW_POWERED_BY: false,
    //       SHOW_PROMOTIONAL_CLOSE_PAGE: false,
    //     },
    //     onload: this.onIFrameLoad,
    //     // jwt: this.token,
    //   }
    // },
    // token() {
    //   return generateToken({
    //     context: {
    //       user: {
    //         name: 'John Doe',
    //         email: 'johndoe@mail.com',
    //       },
    //     },
    //     aud: 'hasandigitalinovasi',
    //     iss: 'hasandigitalinovasi',
    //     sub: 'aws.hasanteknologi.id',
    //     room: '*',
    //   })
    // },
  },
  methods: {
    showModal() {
      this.visibleModal = true
    },
    handleOkStatusTahunAjaran(e) {
      this.confirmLoadingModal = true
      this.$store.dispatch('admin/UPDATE_STATUS_TAHUN_AJARAN', { id: this.selectedTahunAjaran })
        .then(res => {
          this.visibleModal = false
          this.confirmLoadingModal = false
          if (res === 'SUCCESS_UPDATE_STATUS_TAHUN_AJARAN') {
            this.$notification.success({
              message: 'Success.',
              description: 'Tahun Ajaran has been updated',
            })
            this.fetchTahunAjar()
          } else {
            this.$notification.error({
              message: 'Error.',
              description: 'Tahun Ajaran has not been updated',
            })
          }
        })
    },
    handleCancelStatusTahunAjaran(e) {
      this.visibleModal = false
    },
    changeTahunAjaran(payload) {
      this.selectedTahunAjaran = payload
      this.showModal()
    },
    fetchTahunAjar() {
      this.$store.dispatch('user/FETCH_TAHUN_AJARAN')
        .then(res => {
          this.dataTahunAjaran = res
        })
    },
    onIFrameLoad () {
      // $('iframe[name="jitsiConferenceFrame0"] document').ready(() => {
      // $('button[name="jqi_state0_buttonspandatai18ndialogIamHostIamthehostspan"]').trigger('click')
      // $('.aui-dialog2-content input[name="username"]').val('moderator')
      // $('.aui-dialog2-content input[name="password"]').val('moderator')
      // this.$refs.jitsiRef.addEventListener('participantRoleChanged', (event) => {
      // if (event.role === 'moderator') {
      //   this.$refs.jitsiRef.executeCommand('password', 'conference_password')
      // }
      // })
      // })
    },
  },
}
</script>

<style>
</style>
