<template>
  <div>
    <ul class="list-unstyled">
      <li v-for="item in listData" :key="item.id" :class="$style.item">
        <a @click.prevent="listChange(item.id)" :class="$style.itemLink">
          <div
            :class="[
              $style.itemPic,
              item.status === 'aktif'
                ? 'bg-primary'
                : 'bg-transparent border border-primary',
            ]"
          >
            <a-icon v-if="item.status === 'aktif'" type="check" />
          </div>
          <div class="mr-2">
            <div>{{ item.tahun }} ({{ item.semester }})</div>
            <div class="text-muted">
              {{ parseMoment(item.tanggal_awal) }} -
              {{ parseMoment(item.tanggal_akhir) }}
            </div>
          </div>
          <!-- <div :class="$style.itemAction">
            <p>Active</p>
          </div> -->
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'ListTahunAjaran',
  props: {
    listData: {
      type: Array,
      required: true,
    },
    active: {
      required: false,
    },
  },
  data() {
    return {
      isActive: null,
      dataTahunAjaran: [
        {
          tahun: '2020/2021',
          semester: 'GANJIL',
          tanggal_awal: '13 July',
          tanggal_akhir: '25 December',
        },
        {
          tahun: '2020/2021',
          semester: 'GENAP',
          tanggal_awal: '04 January',
          tanggal_akhir: '18 June',
        },
      ],
    }
  },
  methods: {
    parseMoment(date) {
      return moment(date).format('DD MMMM')
    },
    listChange(e) {
      this.$emit('change', e)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
